<template>
  <v-container>
    <comboCompany
     @fromBuilding="monitorInfo"
    />

    <div class="flex">
      <v-select  
        class="selectedHeaders"
        v-model="selectedHeaders" 
        :items="headers" 
        label="조회 지표" 
        density="comfortable"
        item-disabled="disabled"
        :menu-props="{ top: false, offsetY: true }"
        multiple 
        outlined 
        return-object
        dense
        >
        <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action >
              <v-checkbox :input-value="active" color="#80a087"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >
                  <div style="color:#3a4f3f;"> {{ item.text }}</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 4" class="selectChip">
              <span >{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 3" class="grey--text caption"> 등 </span>
          </template>
      </v-select>

      <div>
        <WeatherInfo
          :weather_info="weather_info"
          @gowatt="gowatt(null)"
        />
      </div>
    </div>

    <div>
      <table v-if="loading">
        <thead>
          <tr>
            <th v-for="(header, index) in showHeaders" :key="index">{{ header.text }}</th>
          </tr>
        </thead>
        <tbody>
          <tr> <!-- 5개의 가짜 행 생성 -->
            <td v-for="header in showHeaders" :key="header.text">
              <div class="skeleton"></div> <!-- 스켈레톤을 위한 div -->
            </td>
          </tr>
        </tbody>
      </table>


      <table v-else>
        <thead>
          <tr>
            <th v-for="(header, index) in showHeaders" :key="index">
              {{ header.text }} <!-- Header의 text 값 표시 -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, row) in items" :key="row">
            <!-- selectedHeaders에 맞춰 동적으로 td 생성 -->
            <!-- <td v-for="(header, col) in selectedHeaders" :key="col">-->
              <!-- {{ item[header.value] }} item에서 header.value에 해당하는 값을 표시 -->
            <!--</td>-->
            <td v-if="showColumn('room_name')"  style="background-color: #e9e9e9;">{{ item.room_name }}</td>
            <td v-if="showColumn('temp')">{{ item.temp }} <span :class="(item.temp == '-') ? 'no_unit' : 'unit' "> ℃</span> </td>
            <td v-if="showColumn('highestTemp')" class="text_red">{{ item.highestTemp }}</td>
            <td v-if="showColumn('lowestTemp')" class="text_blue">{{ item.lowestTemp }}</td>
            <td v-if="showColumn('feel')" class="text_gray">{{ item.feel }} </td>
            <td v-if="showColumn('hum')">{{ item.hum }} <span :class="(item.hum == '-') ? 'no_unit' : 'unit' "> % </span></td>
            <td v-if="showColumn('co2')">{{ item.co2 }} <span :class="(item.co2 == '-') ? 'no_unit' : 'unit_ppm' "> ppm</span></td>
            <td v-if="showColumn('nh3')">{{ item.nh3 }} <span :class="(item.nh3 == '-') ? 'no_unit' : 'unit_ppm' "> ppm</span></td>
            <td v-if="showColumn('h2s')">{{ item.h2s }}  <span :class="(item.h2s == '-') ? 'no_unit' : 'unit_ppm' "> ppm</span></td>
            <td v-if="showColumn('checkTime')"> <span :class="( item.validTime || item.checkTime == '-') ? '' : 'cell_red text_white'"> {{ item.checkTime }} </span> </td>
            <td v-if="showColumn('pig_cnt')">{{ item.pig_cnt }}</td>
            <td v-if="showColumn('nowVent')" class="fan_info">{{ item.nowVent }}</td>
            <td v-if="showColumn('now_temp')">{{ item.now_temp }}</td>
            <td v-if="showColumn('action')">
              <!-- 서비스 해당되는 지표조회 -->
               <button class="link_btn" @click="gotoBarometer(item)">
                지표조회
               </button>

               <button class="link_btn" @click="gotoEvent(item)" v-if="item.services.dailyrecord">
                관리일지
               </button>

               <button class="link_btn" :class="{ link_btn_red : item.now_mode === '' , link_btn_white : item.now_mode === 0}" 
                @click="gotoSettingControl(item)" v-if="item.services.fancontrol && item.controllers[0].mac">
                환기제어
               </button>

               <button class="link_btn" :class="{ link_btn_red : item.now_mode === '' , link_btn_white : item.now_mode === 0}"
                v-else-if="item.controllers[0].mac" @click="gotoSettingControl(item)">
                출력현황
              </button>

            </td>

          </tr>
        </tbody>
      </table>
    </div>

    <div style="display:flex;">
      <v-select dense outlined class="interval" 
        id="setInterval" 
        ref="setInterval"
        label="" 
        :items="setIntervalItems"
        :menu-props="{ top: false, offsetY: true }"
        item-text="descripton"
        return-object
        v-model="setInterval"
        @change="refreshList()"
      />
      <span class="mt-3 ml-1">마다 새로고침</span>  
    </div>
  </v-container>
</template>

<script>
import comboCompany from "@/components/combo/fromBuilding.vue";
import WeatherInfo from "./child/WeatherInfo.vue";

import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";

var setTime = 0;
var header_list = [];
// var changed = false;

export default {
  name: 'compoTest',
  components:{
    comboCompany,
    WeatherInfo
  },
  props:{
    company : {
      type: Object,
    },
    farm : {
      type: Object,
    },
    building : {
      type: Object,
    },
  },
  data(){
    return{
      mobileBreakpoint: 600,

      icon_watt: require("@/assets/monitoring/lightning-bolt.svg"),

      selectData: { company: {}, farm: {},building: {} },
      weather_info: {current: {weather_icon: "unknown"},tomorrow: {weather_icon: "unknown"}},

      items:[],

      headers: [],
      headersMap: {
        room_name : {text: '구역', value: 'room_name', groupable: false, selected: true , disabled: true},
        temp : {text: '온도', value: 'temp', groupable: false, selected: true, disabled: true },
        highestTemp : {text: '최고', value: 'highestTemp', width: '50px', groupable: false, selected: true },
        lowestTemp : {text: '최저', value: 'lowestTemp', width: '50px', font_size: '5px',groupable: false, selected: true },
        feel : {text: '체감', value: 'feel', width: '70px', groupable: false, selected: true },
        hum : {text: '습도', value: 'hum', groupable: false, selected: true },
        co2 : {text: 'CO2', value: 'co2', groupable: false, selected: true },
        nh3 : {text: '암모니아', value: 'nh3', groupable: false, selected: true },
        h2s : {text: 'NH3', value: 'h2s', groupable: false, selected: true },
        checkTime : {text: '시간', value: 'checkTime', groupable: false, selected: true, disabled: true },
        pig_cnt : {text: '두수', value: 'pig_cnt' , groupable: false, selected: true},
        nowVent : {text: '팬출력', value: 'nowVent', groupable: false, selected: true },
        now_temp : {text: '팬온도', value: 'now_temp', groupable: false, selected: true },
        action : {text: '바로가기', value: 'action', groupable: false, selected: true, disabled: true, sortable:false },
      },
      selectedHeaders: [],

      setInterval: {min: 30, descripton: "30 분"},
      setIntervalItems: [
        { min: 10, descripton: "10 분"},
        { min: 20, descripton: "20 분"},
        { min: 30, descripton: "30 분"},
        { min: 60, descripton: "1 시간"},
      ],

      isreload: false,
      msgFlag: true,
      loading:false,

    }
  },

  async created() {
    this.headers = Object.values(this.headersMap);

    Apis.getIpClient((res) => {
      // console.log(res);  
      let client = res
      Apis.pageCount({ //페이지뷰 카운터
        page_name: `${client.city}/${client.country}/${client.region}/${client.loc}/${client.org}/${client.postal}/${client.timezone}`,
        url: this.$route.path,
        ip: client.ip,
        division: "pigro",
      },() => {  // 정상처리
        // console.log("pageCount 호출",res);
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("pageCount 호출 오류",err);
      });
    }).catch( (err) => {  // API 오류 처리
        console.log("getIpClient 호출 오류",err)
    }) 

    let cookie_interval = VueCookies.get("summary_interval");
    if (cookie_interval !== "undefined" && cookie_interval) {
      this.setInterval = cookie_interval;
    } 

  },
  computed:{
    showHeaders () {
      let name_list = [];
      this.selectedHeaders.forEach( item => name_list.push(item.value));   // 선택 항목 텍스트 리스트
      // if (!changed) {  
      let cookie = this.headers.filter(s => name_list.includes(s.value));  // 선택항목 오브젝트 리스트
      // VueCookies.set(this.selectData.farm.code + "_selected_monitoring_summary",{ list: cookie, name_list: name_list},"30d");  // 농장에대한 선택 쿠키 저장
      localStorage.setItem(this.selectData.farm.code + "_selected_monitoring_summary",JSON.stringify({ list: cookie, name_list: name_list}));  // 농장에대한 선택 쿠키 저장
      // }
      header_list = name_list
      // changed = false;  

      return this.headers.filter(s => header_list.includes(s.value));   // 선택항목 오브젝트 리스트 리턴
    },

  },

  methods:{
    showColumn(col){

      return this.selectedHeaders.find(s => s.value === col);
    },
    monitorInfo(data) {
      // comboCompany에서 emit된 데이터를 저장
      clearTimeout(setTime);
      this.selectData = data;
      this.refreshList();
    },
    refreshList(){
      let cookie = JSON.parse(localStorage.getItem( this.selectData.farm.code + "_selected_monitoring_summary"));  // 쿠키에 저장된 농장 선택 해드리스트 
      if (cookie && cookie.list.length > 0) {
        this.selectedHeaders = cookie.list;
        header_list = cookie.name_list;
        }
      else { // 없으면 초기화
        this.selectedHeaders = this.headers;
        this.selectedHeaders.forEach( item => header_list.push(item.value));
      }
      if (this.msgFlag) this.$store.commit("resMessage","");
      if (!this.selectData.farm || !this.selectData.farm.code ) {
        alert("축사/건물/구역 또는 농장/사업장를 선택하세요");
        this.$refs.selectData.building.focus();
        return;
      }
      this.loading = true;

      this.refreshWeatherInfo();

      Apis.monitoringSummary({
        farm_cd: this.selectData.farm && this.selectData.farm.code || "",
        building_cd: this.selectData.building && this.selectData.building.code || "",
        stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
      } ,(res) => {  // 정상처리
        this.items = res.data;

        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.loading = false;

        let cookie_interval = this.setInterval;
        VueCookies.set("summary_interval" ,cookie_interval,"30d");
        this.$route.path == "/monitoring/summary" ? setTime = setTimeout( () =>  this.refreshList(), this.setInterval.min * 60 * 1000) : "" ;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("monitoringSummary 호출 오류",err);
        // alert("monitoringSummary 호출 오류" + err);
        this.$router.push("/");
        this.loading = false;
      })
    },
    refreshWeatherInfo(){
      Apis.monitoringWeather({
        farm_cd: this.selectData.farm.code,
      } ,(res) => {
        // NOTE: 해당 농장/사업장정보에 등록된 위치정보 기반 날씨 정보
        this.weather_info = res.data;
      });
    },
    getNowControllerValue() {
      this.items.forEach(((element,index) => {
        if (!element.controllers[0].mac) return;
        // console.log(element.controllers[0].mac,element.controllers[0].slave_id);
        setTimeout( async function() {
          // 슬레이브#2 현재온도 2022-05-27 혜린 추가요청
          if (element.controllers[1].mac) {
            await Apis.getNowControllerValue({
              mac: element.controllers[1].mac,
              slave_id: element.controllers[1].slave_id,
            } ,(res) => { 
              element.now_temp =  res.data.now_temp;
              });
          }
          // 슬레이브#1 현재온도
          if (element.controllers[0].mac) {
            await Apis.getNowControllerValue({
              mac: element.controllers[0].mac,
              slave_id: element.controllers[0].slave_id,
            } ,(res) => { 
              element.now_temp =  res.data.now_temp  + "∕" + element.now_temp;
              element.nowVent =  res.data.now_vent;
              });
          }},
          300 * index);
          }),
        )
    }, 
    async getFanTemp(item) {
      if (!item.controllers[0].mac) return;
        // 슬레이브#2 현재온도
      this.loading = true;

      await Apis.getNowControllerValue({
          mac: item.controllers[0].mac,
          slave_id: item.controllers[0].slave_id,
        },
        res => { 
          item.now_temp =  res.data.now_temp
          item.nowVent =  res.data.now_vent;
          this.loading = false;
        }
      );

      if (item.controllers[1]) {
        Apis.getNowControllerValue({
          mac: item.controllers[1].mac || "",
          slave_id: item.controllers[1].slave_id || 0,
          },
          res => { 
            item.now_temp  = item.now_temp + "∕" + res.data.now_temp;
          })
      }
    }, 
    clickRow(item,row) {
      console.log("click Row",item, row)
      row && row.select(true);
    },
    gotoBarometer(item) {
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));
      this.$router.push({
        name: "Barometer",
        params: { 
          company: this.selectData.company,
          farm: this.selectData.farm,
          building: {code: item.building_cd, name: item.building_name},
          room: {code: item.room_cd, name: item.room_name + " " + item.farm_name},
          }
      });
    },
    gotoEvent(item) {
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));
      // console.log(item)
      this.$router.push({
        name: "TotalEvents",
        params: { 
                  farm: { company_cd: this.selectData.company.code, company_name: this.selectData.company.name,
                          code: this.selectData.farm_cd, name: item.farm_name,},
                  building: { company_cd: this.selectData.company.code, company_name: this.selectData.company.name,
                              farm_cd: item.farm_cd, farm_name: item.farm_name,
                              code: item.building_cd, name: item.building_name},
                  room: { company_cd: this.selectData.company.code, company_name: this.selectData.company.name,
                          farm_cd: item.farm_cd, farm_name: item.farm_name,
                          building_cd: item.building_cd, building_name: item.building_name,
                          code: item.room_cd, name: item.room_name + ' ' + item.farm_name},
                }
      });
    },
    gotoSettingControl(item) {
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));

      if (item.services.fancontrol) {
        this.$router.push({
          name: "ControlSet",
          params: { building: item.building_cd, room: item.room_cd }
        });
      } else {
      this.$router.push({
          name: "SettingControl",
          params: {
            company: this.selectData.company,
            farm: this.selectData.farm,
            building: {code: item.building_cd, name: item.building_name},
            room: {code: item.room_cd, name: item.room_name + ' ' + item.farm_name},
          }
        });
      }
    },

    gowatt(building){
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));
      this.$router.push({
        name: "WattageDetail",
        params: { company:  this.selectData.company, farm:  this.selectData.farm, building:building }
      });
    }

  }
}
</script>

<style>
.selectedHeaders{
  width: inherit;
}

/* 기본 테이블 스타일 */
table {
  width: 100%;
  border-collapse: collapse; /* 테이블 셀 간의 공백 제거 */
  margin: 20px 0;
  font-size: 16px;
  text-align: center; /* 기본 텍스트 정렬 */
}

/* 테이블 헤더 스타일 */
thead th {
  background-color: #647b69; /* 헤더 배경색 */
  color: white; 
  padding: 10px; 
}

/* 테이블 본문 스타일 */
tbody td {
  padding: 10px; /* 셀 간의 간격 */
  border: 1px solid #ddd; /* 셀 테두리 */
}

/* 마우스를 올렸을 때 하이라이트 */
tbody tr:hover {
  background-color: #f1f1f1; 
}

/* 그룹 헤더 스타일 */
.group-header {
  background-color: #e0e0e0;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}


.flex{
  display:flex;
  justify-content: space-between;
}

/* 조회지표 내부 */
.v-list .v-list-item--link:before{
  opacity: 0;
}
.v-list-item--disabled{
  display:none !important;
}
.v-input--dense.v-text-field--outlined .v-chip {
  height: 1.5rem;
  margin-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
  font-size:12px;
  letter-spacing: -1px;
}
/* .v-select__selections .selection_Chip :first-child{
  display:none;
} */
.v-select__selections span:nth-child(2){
  display:none;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.link_btn{
  margin-right:  3px;
  width: 45px ;
  height: 25px;
  line-height: 0px;
  border-radius: 30px;
  background-color: #3a4f3f;
  line-height: 10px;
  color: white;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.5px;
  cursor: pointer;
 }
.link_btn_white{
  color: #3a4f3f;
  border: solid 0.5px;
  background-color: #f3f8f3;
 }
.link_btn_red{
  color: #3a4f3f;
  background-color:  #88191a;
}

.text_red{
  color:#88191a;
  font-size: 14px !important;
}
.cell_red{
  background-color:#88191a !important;
  font-weight:500;
  padding:8px;
  border-radius:10px;
  color:white;
  animation: blink-effect 1s step-end infinite;
}
.interval{
  width:7rem;
}
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
.text_blue{
  color:#004297;
  font-size: 14px !important;
}
.text_red{
  color:#88191A;
}
.text_gray{
  color:#5c5c5c;
  font-size: 15px !important;
}
.unit{
  color:#5c5c5c;
  font-size: 12px !important;
}
.unit_ppm{
  color:#5c5c5c;
  font-size: 10px !important;
}
.no_unit{
  font-size: 0px !important;
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  text-align: left;
  letter-spacing: -1.2px;
}

.icon_bolt{
  width:15px;
  filter: invert(77%) sepia(86%) saturate(346%) hue-rotate(340deg) brightness(100%) contrast(94%);
}
.watt_info{
  display:inline-block;
  background:white;
  width:100px;
  padding:1px;
  margin-left:5px;
  border-radius: 10px;
  cursor:pointer;
}
.watt_number{
  display:inline-block;
  width:60px;
  letter-spacing: -1px;
  text-align:center;
}
.watt_info:hover{
  background: #f8ffcf;
  transition: all .5s ease;
}
.header_layout{
  margin-top: 5px; 
  display:flex; 
  justify-content: flex-end;
}

.skeleton {
  background-color: #f0f0f0;
  height: 20px;
  width: 100%;
  border-radius: 4px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .header_layout{
    flex-direction: column; 
  }
  .selectedHeaders{
    margin:5px !important;
    max-width:400px;
    height:40px;
  }
  .v-data-table::v-deep{
    table{
      td:first-child{
        font-size:0.8rem !important;
        width:150px !important;
      }
    }
  }
}
</style>