<template>
  <v-container>
    <v-card rounded-pill max-width="800px" class="select mx-auto" color="transprent">
      <div class="flex">
        <div class="flex-col">
          <label for="company">경영체</label>
          <v-select dense outlined 
            id="company"
            ref="company" 
            v-model="company"
            :items="companyItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @change="changeCompany()"
            @focus="comboCompanies()"
            clearable
            >
          </v-select>
        </div>

        <div class="flex-col">
          <label for="farm">{{ bizFarm_name }}</label>
          <v-select dense outlined
            id="farm" 
            ref="farm" 
            v-model="farm"
            :items="farmItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @change="changeFarm()"
            @focus="comboFarms()"
            clearable
            >
          </v-select>
        </div>

        <div class="flex-col">
          <label for="building">{{ bizBuilding_name }}</label>
          <v-select dense outlined
            id="building" 
            ref="building" 
            v-model="building"
            :items="buildingItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @change="changeBuilding()"
            @focus="comboBuildings()"
            clearable
            >
          </v-select>
        </div>

        <v-btn 
          class="basic_btn" 
          @click="fromBuilding()">
          <v-icon color="white">
            mdi-magnify
          </v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";

export default {
  name: 'combobox',
  data(){
    return{
      loading: false,

      bizFarm_name:"사업장",
      bizBuilding_name:"건물",

      company : {},
      companyItems : [],
      farm : {},
      farmItems : [],
      building : {},
      buildingItems : [],
    }
  },
  async created(){

    let cookie_info = VueCookies.get("summary_info");

    // 세션 스토리지에서 paramInfo 가져오기
    let paramInfo = JSON.parse(sessionStorage.getItem("paramInfo"));
    if (paramInfo) {
      cookie_info = paramInfo;
      sessionStorage.removeItem("paramInfo");
    }

    // 쿠키나 세션 스토리지에서 정보 설정
    if (cookie_info) {
      this.company = cookie_info.company || {};
      this.farm = cookie_info.farm || {};
      this.building = cookie_info.building || {};
    } else {
      this.farm = this.farmItems[0];
      this.company = { code: this.farm.company_cd, name: this.farm.company_name };
    }

    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();

    this.fromBuilding();
  },
  watch: {
    farm(newFarm) {
      if (newFarm && newFarm.biz) {
        switch (newFarm.biz) {
          case "CF03":
          case "CF99":
          case "CF01":
            this.bizFarm_name = "사업장";
            this.bizBuilding_name = "건물";
            break;
          case "CF02":
          case "CF04":
            this.bizFarm_name = "농장";
            this.bizBuilding_name = "축사";
            break;
          default:
            this.bizFarm_name = "사업장";
            this.bizBuilding_name = "건물";
            break;
        }
      } else {
        this.bizFarm_name = "사업장";
        this.bizBuilding_name = "건물";
      }
    },
  },
  methods:{
    async comboCompanies() {
      await Apis.comboCompanies({
        new_version : true,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company.code,
              name: res.data[i].company.name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },
    async changeCompany() {   // 하위 콤보 지우기;
      this.farm = {}; 
      this.farmItems = [];
      this.building = {}; 
      this.buildingItems = [];

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        new_version : true,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];

            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company.code, 
              company_name: res.data[i].company.name, 
              code: res.data[i].farm.code,
              name: res.data[i].farm.name,
              biz: res.data[i].farm.biz
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        // await this.comboBuildings();

      }
        this.building = {};
        this.buildingItems = [];
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        new_version : true,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company.code, 
              company_name: res.data[i].company.name, 
              farm_cd: res.data[i].farm.code, 
              farm_name: res.data[i].farm.name, 
              code: res.data[i].building.code,
              name: res.data[i].building.name,
              group: res.data[i].building.group,
              biz: res.data[i].building.biz
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding(){
      if (this.building && this.building.farm_cd) {
        // console.log(this.building)
        this.company = {code: this.building.company_cd, name: this.building.company_name};
        this.farm = {
          company_cd: this.building.company_cd, 
          company_name: this.building.company_name,
          code: this.building.farm_cd,
          name:this.building.farm_name,
          biz: this.building.biz
        };
        this.farmItems = [this.farm]; 
      }
      // console.log(this.farm)
    },
    fromBuilding() {
      this.$emit('fromBuilding', {
        company: this.company,
        farm: this.farm,
        building: this.building
      });
      let cookie_info = { company: this.company, farm: this.farm, building: this.building };
      VueCookies.set("summary_info" ,cookie_info,"30d");
    }
  }
}
</script>

<style>
/* base로 빼야함 */
.v-main{
  background-color: #f8f8f8;
}
.v-select { 
  flex: none;

  .v-input__control {
    height:2.5rem;
    width: inherit;
  
    .v-input__slot {
      border-radius: 10px;
      min-height: initial !important;
      width: inherit;
      margin: 0;
    }

    .v-select__slot {
        height:2.5rem;
    }
  }

  .v-select__selections {
      padding: 0 !important;
  }

  .mdi-menu-down::before {
    color: #3a4f3f;	
    content: "\F0140"; 
  }
}

.v-menu__content{
  border-radius: 0 0 15px 15px !important;
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}
.v-list-item__title{
  letter-spacing: -1px;
  font-size:15px !important;
}

.flex-col{
  display:flex;
  flex-direction: column;
  margin-right:.5rem;
}
.flex{
  display:flex;
}
label {
  font-size: .9rem;
  padding-right: 10px;
  font-weight:600;
}
.basic_btn{
  background-color:#3a4f3f !important;
  margin-top:10px;
  border-radius: 20px;
  height:2.5rem !important;
}
.v-select__slot {
  max-width:12rem;
  /* height:2.5rem; */
}
.select{
  padding:10px;
  /* box-shadow: none; */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

  border:1px solid #ddd !important;
  border-radius: 20px !important;
}
</style>